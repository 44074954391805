import { GatsbyImage, StaticImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import React from 'react';
import { css } from '@emotion/react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { device, colors } from './layout/GlobalStyles';
import logo from '../images/vytal-logo.png';
import { Button } from './layout/StyledComponents';
import marbel from '../images/marbel.jpg';

const HomeStaffStyled = styled.section`
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 1em;
    position: relative;
    .marbel {
        position: absolute;
        z-index: 1;
        width: 195px;
        height: 195px;
        top: 2em;
        left: -1em;
    }
    .image-logo-container {
        z-index: 2;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        .image {
            height: 400px;
        }
        .logo {
            bottom: 60px;
            position: relative;
        }
    }
    p {
        margin: 1em 0em;
    }
    button {
        margin-top: 1em;
    }
    @media ${device.tablet} {
        padding: 6em 1em;
        h1 {
            margin-bottom: 1em;
        }
        .image-logo-container {
            margin-bottom: -80px;
        }

        .marbel {
            top: 4em;
            width: 315px;
            height: 305px;
        }
    }
    @media ${device.laptop} {
        flex-direction: row;
        gap: 50px;
        .marbel {
            left: 2em;
            width: 315px;
            height: 305px;
        }
        .image-logo-container {
            .image {
                height: 50vh;
                min-width: 50vw;
                max-width: 900px;
            }
        }
    }
    @media ${device.laptopL} {
        align-items: center;
    }
    @media ${device.desktop} {
    }
    @media ${device.desktopL} {
    }
`;

export const HomeStaff: React.FC = () => {
    return (
        <HomeStaffStyled>
            <img className="marbel" src={marbel} alt="marbel pattern" />
            <div className="image-logo-container">
                <StaticImage
                    alt="group photo of the dentist team"
                    src="../images/staff/staff.jpeg"
                    quality={100}
                    className="image"
                />
                <StaticImage
                    quality={100}
                    className="logo"
                    src="../images/svgs/logo-circle-green.svg"
                    placeholder="none"
                    alt="green vytal logo"
                />
            </div>
            <div className="text">
                <h1>Medical Center in Tarzana California</h1>
                <p>
                    We offer a comprehensive range of first class cosmetic surgeries and modern
                    non-surgical treatments to patients. You can rest assured that Vytal Surgical
                    Institute is registered, and undergoes regular inspections, by the care quality
                    commission.
                </p>
                <p>
                    Our team of dedicated health professionals are here to serve you. We combine
                    extensive knowledge in the field of surgery with a passion for helping people
                    solve their health problems. It is our goal to create a friendly and
                    professional atmosphere that you can rely on for all of your health needs.
                </p>
                <Link to="/about-us">
                    <Button color={`${colors.black}`}>ABOUT US</Button>
                </Link>
            </div>
        </HomeStaffStyled>
    );
};
