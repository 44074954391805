import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from '@emotion/styled';
import { device, colors } from './layout/GlobalStyles';
import { Button } from './layout/StyledComponents';

const StyledSiteLinks = styled.section`
    padding: 2em 0.5em;
    h2 {
        margin: 1.2em 0em 0.75em;
    }
    p {
        margin-bottom: 1.5em;
    }
    button {
        margin-bottom: 2em;
    }
    @media ${device.tablet} {
        margin: 0 auto;
        max-width: 840px;
        .container-r {
            flex-direction: row;
            display: flex;
            margin: 2em 0em;
            .text {
                width: 50vw;
                padding-left: 5em;
            }
        }
        .container {
            flex-direction: row-reverse;
            display: flex;
            .text {
                width: 50vw;
                padding-right: 5em;
            }
        }
        img {
            width: 340px;
        }
    }
    @media ${device.laptopL} {
        .container-r {
            flex-direction: row;
            display: flex;
            margin: 2em 0em;
            .text {
                width: 35vw;
                padding-left: 5em;
            }
        }
        .container {
            flex-direction: row-reverse;
            display: flex;
            .text {
                width: 35vw;
                padding-right: 5em;
            }
        }
    }
`;

export const SiteLinks: React.FC = () => {
    return (
        <StyledSiteLinks>
            <div className="container">
                <StaticImage
                    quality={100}
                    src="../images/girl-hazel-eyes.jpg"
                    alt="girl with hazel eyes"
                />
                <div className="text">
                    <h2>VYTAL AESTHETICS</h2>
                    <p>
                        We offer a comprehensive range of first class cosmetic surgeries and modern
                        non-surgical treatments to patients. You can rest assured that Vytal
                        Surgical Institute is registered, and undergoes regular inspections, by the
                        care quality commission.
                    </p>
                    <a href="https://vytalcenter.com/" target="__blank" rel="nofollow">
                        <Button color={colors.primary}>GO TO VYTALAESTHETICS.COM</Button>
                    </a>
                </div>
            </div>

            <div className="container-r">
                <StaticImage
                    quality={100}
                    src="../images/girl-face-drawing.jpg"
                    alt="girl with surgery lines on face"
                />
                <div className="text">
                    <h2>TARZANA OUTPATIENT SURGERY</h2>
                    <p>
                        Tarzana Outpatient Surgical Institute is a first-class medical facility led
                        by physicians specializing in: Ear, Nose & Throat (ENT) Surgery,
                        Gastroenterology Surgery, NeuroSurgery, OB/GYN Surgery, Orthopedic Surgery,
                        Pain Management, Plastic & Reconstructive Surgery, Podiatric Surgery,
                        Urological Surgery, Spine Surgery, and Excellence in Outpatient Healthcare.
                    </p>
                    <a
                        href="https://tarzanaoutpatientsurgicalinstitute.com/"
                        target="__blank"
                        rel="nofollow"
                    >
                        <Button color={colors.primary}>
                            go tarzanaoutpatientsurgicalinstitute.com
                        </Button>
                    </a>
                </div>
            </div>
        </StyledSiteLinks>
    );
};
