import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import logo from '../images/vytal-logo.png';
import mobile from '../images/svgs/mobile.svg';
import marker from '../images/svgs/marker.svg';
import facebook from '../images/svgs/facebook.svg';
import instagram from '../images/svgs/instagram.svg';
import { Button } from './layout/StyledComponents';

const StyledGatsbyImage = styled(GatsbyImage)`
    background-size: cover;
    position: absolute;
    height: 100vh;
    z-index: -1;
    @media ${device.tablet} {
        background-size: contain;
    }
`;

const StyledHomeHero = styled.section`
    min-height: 100vh;
    padding: 0em 1em;
    color: ${colors.white};
    .play-button-wrap {
        border: none;
        background: none;
        position: relative;
        p {
            position: absolute;
            left: 15%;
            font-family: 'Bebas Neue', cursive;
            color: ${colors.white};
            font-size: 21px;
        }
    }
    h6 {
        font-size: 18px;
        text-align: center;
        font-family: 'Bebas Neue', cursive;
    }
    .logo {
        margin: 0 auto;
        padding-top: 7em;
        padding-bottom: 1em;
        border-bottom: 2px solid ${colors.white};
        width: 190px;
        height: auto;
    }
    h2 {
        margin: 1em 0em 1em;
        text-align: center;
        color: ${colors.white};
        font-size: 24px;
        font-weight: 500;
        line-height: 155.7%;
        letter-spacing: 0.19em;
    }
    .sub-header-mobile {
        justify-content: space-around;
        max-width: 320px;
        margin: 0 auto;
        align-items: baseline;
        display: flex;
        img {
            width: auto;
            margin: 1em auto;
        }
        .mobile {
            height: 20px;
        }
        .marker {
            height: 25px;
        }
    }
    .social {
        img:first-child {
            margin-top: 2em;
            margin-left: 1em;
            margin-bottom: 1em;
        }
    }
    .buttons {
        margin: 3em 0em 0.5em 5em;
        button {
            position: relative;
            top: 0.25em;
            margin: 0.5em 0em;
            min-width: 200px;
        }
    }
    .button-flex {
        display: flex;
        align-items: flex-end;
    }
    @media ${device.mobileL} {
        .logo {
            margin: 0 auto;
            padding-top: 8.5em;
        }
    }
    @media ${device.tablet} {
        .social {
            img:first-child {
                margin-left: 0em;
                margin-bottom: 3em;
            }
        }
        .play-button-wrap {
            p {
                left: 45%;
                bottom: -8%;
                font-size: 22px;
            }
        }
        padding: 0em 2em;
        .logo {
            padding-top: 10em;
            padding-bottom: 3.3em;

            border-bottom: none;
        }
        h2 {
            border-top: 1px solid ${colors.white};
            font-size: 38px;
            margin: 0 auto;
            padding-top: 1.33em;
            width: 524px;
        }
        .social {
            margin-left: 1em;
        }
        .buttons {
            padding: 0em;
            margin-top: 13.5em;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            button {
                top: -1.5em;
            }
            div {
                width: 0px;
                margin: 0em 4em;
                height: 2px;
                background: ${colors.white};
            }
            img {
                height: 148px;
                width: auto;
                margin: 0em 2em 0em 6em;
                padding: 2em;
            }
        }
        .sub-header-mobile {
            display: none;
        }
    }
    @media ${device.laptop} {
        h2 {
            font-size: 48px;
        }
        .buttons {
            margin: 0 auto;
            button {
                top: 0em;
            }
            div {
                width: 400px;
            }
            margin-top: 0em;
        }
        .logo {
            padding-top: 10em;
            width: 240px;
        }
    }
    @media ${device.laptopL} {
        .social {
            position: relative;
            top: 2em;

            img {
                height: 26px;
            }
        }
        h2 {
            font-size: 48px;
            margin-bottom: -1.5em;
        }
        .buttons {
            button {
                top: 0em;
            }
            margin: 7em auto 0em;
            div {
                width: 550px;
            }
        }
        .logo {
            padding-top: 8em;
            width: 300px;
        }
    }
    @media ${device.desktop} {
        .buttons {
            margin: 7em auto 0em;
            div {
                width: 750px;
            }
        }
        .logo {
        }
        h2 {
            margin-bottom: -1em;
            font-size: 59px;
            width: 700px;
        }
        .logo {
            padding-top: 10em;
            width: 370px;
        }
        .social {
            img {
                height: px;
            }
        }
    }
    @media ${device.desktopL} {
        position: relative;
    }
`;

const StyledButton = styled(Button)``;

export const HomeHero: React.FC = () => {
    const { desktopImage, mobileImage } = useStaticQuery(graphql`
        query {
            desktopImage: file(relativePath: { eq: "desktop-bg.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 90, webpOptions: { quality: 90 })
                }
            }
            mobileImage: file(relativePath: { eq: "mobile-bg.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 90, webpOptions: { quality: 90 })
                }
            }
        }
    `);

    const images = withArtDirection(getImage(desktopImage.childImageSharp.gatsbyImageData), [
        {
            media: '(max-width: 767px)',
            image: getImage(mobileImage.childImageSharp.gatsbyImageData),
            alt: 'group photo of the dentist team',
        },
    ]);

    return (
        <>
            <div>
                <StyledGatsbyImage alt="girl lying on couch" image={images} />
                <StyledHomeHero>
                    <img src={logo} alt="vytal logo" className="logo" />
                    <h2>BEAUTY BEYOND LIMITS</h2>
                    <div className="sub-header-mobile">
                        <a style={{ color: 'white' }} href="tel:8185785125">
                            <div>
                                <img className="mobile" src={mobile} alt="telephone icon" />
                                <h6>(818) 578 5125</h6>
                            </div>
                        </a>
                        <a
                            style={{ color: 'white' }}
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.google.com/maps/place/19228+Ventura+Blvd+a,+Tarzana,+CA+91356,+USA/@52.676695,-7.8065301,14z/data=!3m1!4b1!4m2!3m1!1s0x80c299148b367eb7:0x69910eea314ee511"
                        >
                            <div>
                                <img className="marker" src={marker} alt="map marker icon" />
                                <h6>
                                    19228 Ventura Blvd, Suite <br />
                                    A, Tarzana, CA 91356
                                </h6>
                            </div>
                        </a>
                    </div>
                    <div className="button-flex">
                        <div className="social">
                            <a href="https://www.facebook.com/vytalcenter/" target="__blank">
                                <img className="facebook" src={facebook} alt="facebook logo" />
                            </a>
                            <a href="https://www.instagram.com/vytalcenter/?hl=en" target="__blank">
                                <img className="instagram" src={instagram} alt="instagram logo" />
                            </a>
                        </div>
                        <div className="buttons">
                            <a href="https://vytalcenter.com/" target="__blank" rel="nofollow">
                                <StyledButton color={`${colors.white}`}>
                                    VYTAL AESTHETICS
                                </StyledButton>
                            </a>
                            <div></div>

                            <Link to="book-appointment">
                                <StyledButton color={`${colors.white}`}>
                                    BOOK AN APPOINTMENT
                                </StyledButton>
                            </Link>
                        </div>
                    </div>
                </StyledHomeHero>
            </div>
        </>
    );
};
