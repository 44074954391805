import { HomeHero } from '../components/HomeHero';
import { HomePhotos } from '../components/HomePhotos';
import { HomeStaff } from '../components/HomeStaff';
import { HomeGallery } from '../components/HomeGallery';
import { OurDoctors } from '../components/OurDoctors';
import { ServicesPanel } from '../components/ServicesPanel';
import { SiteLinks } from '../components/SiteLinks';
import { Seo } from '../components/Seo';

const Home: Page = () => {
    return (
        <main>
            <Seo
                title="Medical Center Tarzana, CA | Surgical Center– vytalcenter"
                description="Vytal Center is committed to the highest quality medical care. Our doctors are highly experienced in the surgical field"
            />
            <HomeHero />
            {/* <HomePhotos /> */}
            <HomeStaff />
            <HomeGallery />
            <OurDoctors />
            <ServicesPanel homepage />
            <SiteLinks />
        </main>
    );
};

export default Home;
